import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactform";
import Seo from "../components/seo";
import "../components/layout.css";

/*Component*/

const Contact = () => {
    return (
    <Layout>
      <Seo title="Contact OFM" />
      <section className="center-column">
        <h2 className="contact-title">Contact us here</h2>
        <ContactForm/> 
      </section>
    </Layout> 
)};

export default Contact;