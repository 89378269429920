import React from "react"
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import "./contactform.css";
import "../components/layout.css";

/*Component*/


export default function ContactForm() {

  function sendEmail(e) {
    e.preventDefault();
    const UserID = "user_u6K0zyMdHXT3aMAhGub6G"
    emailjs.sendForm('service_ai4weh6', 'template_3duv2mh', e.target, UserID)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      init("UserID");
      e.target.reset();
  }

  return (
    <form className="contact-form" onSubmit={sendEmail} id="form">
        <div className="form-row">
            <label htmlFor="name">Name:</label>
            <input type="text" name="user_name" id="name" required />
        </div>
        <div className="form-row">
            <label htmlFor="email">Email:</label>
            <input type="email" name="user_email" id="email" required />
        </div>
        <div className="form-row">
            <label htmlFor="message">Message:</label>
            <textarea name="message" id="message" required />
        </div>
        <div className="row">
            <input type="submit" value="SUBMIT" className="form-btn submit" />
        </div>
    </form>
  );
}
